<template>
  <div id="app">
    <TheNavbar/>

    <div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import TheNavbar from './components/TheNavbar.vue';

export default {
  name: 'App',
  components: { TheNavbar },

  created() {
    /*
    * FIXME: The following mutation should not be placed here. Should we have the project logic
    *  implemented, this should be properly placed.
    * */
    this.$store.commit('setProjectMetaData');
  },
};
</script>

<style lang="scss">
html, body {
  margin: 0;
  scroll-behavior: smooth;
}

#app {
  background-color: $applicationBackground;
}
</style>
