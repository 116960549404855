<template>
  <!-- TODO: Use same implementation as AllAppsMenu -->
  <div
    class="navbar-item has-dropdown"
    :class="{ 'is-active': isActive }"
    v-click-away="closeDropdown"
  >
    <div class="icons-container p-2" @click="isActive = !isActive">
      <slot name="icon"></slot>
    </div>
    <div class="navbar-dropdown p-5 mt-1 mx-2"
      :class="{'is-right': !isLeftSide, 'has-background-white': !isLeftSide}"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarDropdownWrapper',

  props: {
    isLeftSide: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    closeDropdown() {
      this.isActive = false;
    },
  },

};
</script>

<style lang="scss" scoped>
.navbar-dropdown {
  min-width: 25rem;
  border-radius: 6px;
}

.has-dropdown {
  padding: 0.5rem 0.75rem !important;
  align-items: center !important;
  position: unset !important;
}

.icons-container:hover {
  background-color: $navbar-item-hover-background-color;
}

.has-dropdown.is-active {
  .icons-container {
    background-color: $navbar-item-hover-background-color;
  }
}

.icons-container {
  border-radius: 50%;
  display: inline-block;
  margin: 0;
  transition: background-color ease-in-out 0.3s;
}

@media screen and (max-width: $desktop) {
  .has-dropdown .is-active {
    .navbar-dropdown {
      display: block;
    }
  }
  .has-dropdown:not(.is-active) {
    .navbar-dropdown {
      display: none;
    }
  }
}
</style>
