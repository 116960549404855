<template>
  <div class="container documentation-dropdown-content">
    <section>
      <p>
        Want to learn more about how TedHy gets its results? Our documentation
        describes and explains how it works!
      </p>
      <p>we also have all the links to our
        sources and references for you to check out.</p>
    </section>
    <section>
      <router-link class="box" to="/documentation#technology"
        >About Technology</router-link
      >
      <router-link class="box" to="/documentation#economics"
        >About Economics</router-link
      >
      <router-link class="box" to="/documentation#environment"
        >About Environment</router-link
      >
    </section>
  </div>
</template>

<script>
export default {
  name: 'TheDocumentationDropdown',
};
</script>

<style lang="scss" scoped>
.documentation-dropdown-content {
  color: black;
  max-width: 20rem !important;
}
</style>
