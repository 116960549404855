<template>
  <div
    class="dropdown-item is-clickable"
    :class="{'has-background-primary-light': isSelected}"
  >
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'MultipleSelectionDropdownItem',

  props: {
    label: String,
    value: [String, Number],
    isSelected: Boolean,
  },

};
</script>

<style lang="scss" scoped>
.dropdown-item:hover {
  background-color: $primary-light;
}
</style>
