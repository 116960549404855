<template>
  <v-chart
    class="chart"
    ref="echart"
    :option="option"
    :update-options="{ notMerge: true, lazyUpdate: true }"
    @legendselectchanged="legendItemSelection"
    autoresize
  />
  <DownloadButton class="download-button" @click="exportAsXlsx"/>
</template>

<script>
import XLSX from 'xlsx';
import 'echarts/lib/component/dataset';
import utilities from '../../../assets/mixins/utilities';
import DownloadButton from '../../DownloadButton.vue';

export default {
  name: 'ChartEnergyBalance',
  components: { DownloadButton },
  props: {
    title: String,
    unit: String,
    variable: [String, Array],
    currentYear: Number,
  },

  data() {
    return {
      jsonData: [],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      option: {
        grid: {
          top: '2%',
          right: '27%',
          left: '0%',
          bottom: '2%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => this.tooltipFormatterConfig(params),
        },
        legend: {
          orient: 'vertical',
          left: '76%',
          top: '3%',
          bottom: '10%',
          textStyle: {
            width: '90',
            overflow: 'break',
          },
          type: 'scroll',
          height: '75%',
        },
        xAxis: {
          type: 'category',
          axisLine: { onZero: false },
        },
        yAxis: {
          axisLabel: {
            formatter: (value) => {
              if (Math.abs(value) >= 1000) {
                return utilities.unitConvert(value, this.unit);
              }
              return `${value} ${this.unit}`;
            },
          },
          max: (value) => (value.max < 0 ? Math.ceil(value.max) : null),
        },
        textStyle: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
      series: [],
      dataset: {
        source: [],
      },
    };
  },

  computed: {
    dataSource() {
      return {
        production: this.$store.state.data.energyBalanceData,
        utilization: this.$store.state.data.selectedSite === 'all'
          ? this.$store.state.data.energyBalanceData
          : this.$store.state.data.energyBalanceSiteData.filtered,
      };
    },
    seriesItems() {
      return this.$store.state.data.energyItems;
    },
    scenarioLabel() {
      return this.$store.state.data.scenarioInformation
        .find((info) => info.id.toString() === this.$route.params.scenarioId).label;
    },
  },

  watch: {
    dataSource(newValue) {
      this.transformData(newValue, this.dataset);
    },
    currentYear() {
      this.transformData(this.dataSource, this.dataset);
    },
    seriesItems(newValue) {
      this.transformSeriesItem(newValue);
    },
  },

  methods: {
    transformSeriesItem(seriesItems) {
      this.series = [];
      seriesItems.forEach((item) => {
        const seriesRow = {
          type: 'line',
          seriesLayoutBy: 'row',
          smooth: 0.3,
          color: item.color,
        };
        if (item.code !== 'total') {
          Object.assign(seriesRow, {
            stack: 'source',
            type: 'bar',
          });
        }
        this.series.push(seriesRow);
      });
      this.option.series = this.series;
    },

    transformData(data, dataset) {
      dataset.source = [];
      dataset.source[0] = ['variable', ...this.monthNames];

      this.seriesItems.forEach((item) => {
        const serieData = [item.label];
        const dataSourceChildName = item.label === 'Utilization' ? 'utilization' : 'production';
        const currentYearData = data[dataSourceChildName]
          .filter((row) => row.year === this.currentYear);
        for (let i = 1; i < dataset.source[0].length; i += 1) {
          const value = currentYearData.find((row) => (row.energy === item.code)
              && (this.variable.includes(row.variable))
              && (i === row.month));
          serieData.push(value ? value.value : undefined);
        }
        dataset.source.push(serieData);
      });
    },

    prepareExportJsonData() {
      this.jsonData = [];
      for (let i = 1; i < this.dataset.source.length; i += 1) {
        for (let j = 1; j < this.dataset.source[i].length; j += 1) {
          const jsonDataItem = {
            scenario_name: this.scenarioLabel,
            site_name: this.$store.state.data.selectedSite,
            graph_name: this.title,
            option: this.dataset.source[0][0] === 'variable' ? 'N/A' : this.dataset.source[0][0],
            legend_item: this.dataset.source[i][0],
            year: this.currentYear,
            month: this.dataset.source[0][j],
            value: this.dataset.source[i][j],
            unit: this.unit,
          };
          this.jsonData.push(jsonDataItem);
        }
      }
    },

    exportAsXlsx() {
      this.prepareExportJsonData();
      const fileName = `${this.title}_${new Date().toISOString().slice(0, 10).replaceAll('-', '')}.xlsx`.replace(/[|&;$%@"<>()+, ]/g, '_');
      const ws = XLSX.utils.json_to_sheet(this.jsonData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Graph data');
      XLSX.writeFile(wb, fileName);
    },

    tooltipFormatterConfig(params) {
      let msg = `<div style="font-weight:900;margin-bottom: 5px">${params[0].axisValueLabel.replace(/\n/g, ' - ')}</div>`;
      params.forEach((param) => {
        const value = param.value[param.seriesIndex + 1] !== undefined
          ? param.value[param.seriesIndex + 1].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
          : 'N/A';
        msg += `<div><span>${param.marker + param.seriesName}</span> <span style="float:right;margin-left:20px">${value} ${value !== 'N/A' ? this.unit : ''}</span></div>`;
      });
      return msg;
    },

    // Prevent deselection if all other items are deselected
    legendItemSelection(params) {
      let selectedCount = 0;
      Object.keys(params.selected).forEach((key) => {
        if (params.selected[key]) selectedCount += 1;
      });

      if (selectedCount === 0) {
        const chart = this.$refs.echart;
        chart.setOption({ animation: false });

        Object.keys(params.selected).forEach((name) => {
          chart.dispatchAction({
            type: 'legendSelect',
            name,
          });
        });

        chart.setOption({ animation: true });
      }
    },
  },

  mounted() {
    this.option.dataset = this.dataset;
    this.option.color = this.$store.state.graphColorSchemes[0].colorPalette5;
    this.transformSeriesItem(this.seriesItems);
    this.transformData(this.dataSource, this.dataset, false);
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 20rem;
  padding: 0rem 1rem 1rem 1rem;
  font-family: inherit;
}
.download-button {
  position: absolute;
  right: 2%;
  bottom: 1%;
}
</style>
