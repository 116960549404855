<template>
  <div>Overview</div>
</template>

<script>
export default {
  name: 'Overview',
};
</script>

<style lang="scss" scoped>
</style>
