<template>
  <li class="steps-segment is-hollow" :class="{'is-active': isActive}" @click="selectYear">
    <span class="steps-marker"></span>
    <p class="steps-content">{{ year }}</p>
  </li>
</template>

<script>
export default {
  name: 'TimelineStep',

  props: {
    year: Number,
  },

  computed: {
    isActive() {
      return this.$store.state.data.selectedYear === this.year;
    },
  },

  methods: {
    selectYear() {
      this.$store.commit('selectYear', this.year);
    },
  },
};
</script>

<style scoped>
.steps-segment {
  cursor: pointer;
  caret-color: transparent;
}
</style>
