<template>
  <SelectionDropdownWrapper>
    <template v-slot:trigger-text>
      {{ dropDownTitle }}
    </template>
    <template v-slot:content>
      <SingleSelectionDropdownItem
        label="All sites"
        value="all"
        variableName="selectedSite"
        callbackName="selectSite"
      />
      <hr class="navbar-divider"/>
      <SingleSelectionDropdownItem
        v-for="site in $store.state.data.sites"
        :key="site.id"
        :label="site.label"
        :value="site.label"
        variableName="selectedSite"
        callbackName="selectSite"
      />
    </template>
  </SelectionDropdownWrapper>
</template>

<script>
import SingleSelectionDropdownItem from '@/components/SingleSelectionDropdownItem.vue';
import SelectionDropdownWrapper from '@/components/SelectionDropdownWrapper.vue';

export default {
  name: 'SiteSelectionDropdown',

  components: {
    SelectionDropdownWrapper,
    SingleSelectionDropdownItem,
  },

  computed: {
    dropDownTitle() {
      return this.$store.state.data.selectedSite === 'all' ? 'All sites' : this.$store.state.data.selectedSite;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
