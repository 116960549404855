<template>
  <div class="action-menu has-background-white">
    <div class="columns is-vcentered is-tab action is-mobile" role="menu">
      <div class="column is-narrow">
        <BurgerButton id="scenario-burger-button"/>
      </div>
      <div class="column is-2">
        <div id="scenario-title-container" @mouseover="animateText">
          <h3 id="scenario-title" class="subtitle has-text-weight-bold">{{ scenarioName }}</h3>
        </div>
      </div>
      <div class="column">
        <div class="action-menu-selects columns is-vcentered">
          <div class="column" v-if="!$route.name.includes('Overview')"
               :class="{'is-narrow': !onEconomicsPage}"
          >
            <site-selection-dropdown class="mr-4"/>
          </div>
          <div class="column"
               v-if="['Supply chain', 'Map'].includes($route.name)"
          >
            <TheTimeline/>
          </div>
          <div class="column is-narrow"
               v-if="onEconomicsPage"
          >
            <div class="field is-grouped vcentered">
              <input
                id="switchExample"
                type="checkbox"
                name="switchExample"
                class="switch is-rounded is-info is-rtl"
                :checked="subsidy"
                @click="toggleSubsidies"
              />
              <label for="switchExample">Subsidies</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteSelectionDropdown from './SiteSelectionDropdown.vue';
import BurgerButton from '../../BurgerButton.vue';
import TheTimeline from '../TheTimeline.vue';

export default {
  name: 'TheActionMenu',

  components: {
    TheTimeline,
    BurgerButton,
    SiteSelectionDropdown,
  },

  computed: {
    subsidy() {
      return this.$store.state.subsidy;
    },

    scenarioName() {
      return this.$store.state.data.selectedScenario.label;
    },

    onEconomicsPage() {
      return this.$route.name === 'Economics';
    },
  },

  methods: {
    toggleSubsidies() {
      this.$store.commit('toggleSubsidy');
    },

    animateText() {
      const titleContainer = document.getElementById('scenario-title-container');
      const title = document.getElementById('scenario-title');

      if (titleContainer.clientWidth < title.clientWidth) {
        title.classList.add('animate');
      } else title.classList.remove('animate');
    },
  },
};
</script>

<style lang="scss" scoped>
#scenario-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#scenario-title-container:hover {
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  #scenario-title {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    display: inline-block;
  }
  .animate {
    position: relative;
    animation: leftright 7s infinite alternate linear;
  }
}

.action-menu-selects {
  display: flex;
}

.select-block {
  width: 100%;
}

.year-item {
  padding: 0.75rem 0.5rem !important;
}

.action-menu {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: $navbar-padding-vertical 1.5rem;
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.2), 0 -29px 20px 0 rgba(0, 0, 0, 0.19);
}

.vcentered {
  align-items: center;
}

@keyframes leftright {
  0%,
  20% {
    transform: translateX(0%);
    left: 0;
  }
  80%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}
</style>
