<template>
  <div class="box">
    <div class="columns is-mobile is-vcentered">
      <div class="column">
        <p>Process step</p>
        <p class="has-text-grey">
          Switch between the available process steps in your supply chain.
        </p>
      </div>
      <div class="column is-narrow">
        <div class="select">
          <select v-model="selectedOption">
            <option v-for="processStep in processSteps"
                    :key="processStep.code"
                    :value="processStep.label"
            >
              {{ processStep.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <section>
    <p class="has-text-weight-bold">About</p>
    <p class="py-1">About the equipment</p>
  </section>
  <section>
    <p class="has-text-weight-bold">Connections</p>
    <p class="py-1">How does it integrate with the other equipment in the supply chain</p>
  </section>
  <section>
    <p class="has-text-weight-bold">Uses</p>
    <p class="py-1">How is the equipment working</p>
  </section>
</template>

<script>
export default {
  name: 'TheLetsDiveIn',

  data() {
    return {
      selectedOption: this.$store.state.data.processSteps[0].label,
    };
  },

  computed: {
    processSteps() {
      return this.$store.state.data.processSteps
        .filter((processStep) => processStep.code !== 'development_sub');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
