/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import scenarioInformation from '@/assets/data/scenarios.json';
import charts from '@/assets/data/charts.json';

export default {
  state: {
    scenarioYearlyData: null,
    scenarioYearlySiteData: {
      initial: null,
      filtered: null,
    },
    scenarioData: null,
    scenarioSiteData: {
      initial: null,
      filtered: null,
    },
    accountingItems: null,
    accountingItemData: null,
    accountingItemSiteData: {
      initial: null,
      filtered: null,
    },
    processSteps: null,
    processStepData: null,
    processStepSiteData: {
      initial: null,
      filtered: null,
    },
    userItems: null,
    userData: null,
    userSiteData: {
      initial: null,
      filtered: null,
    },
    useTypeItems: null,
    useTypeData: null,
    useTypeSiteData: {
      initial: null,
      filtered: null,
    },
    energyItems: null,
    energyData: null,
    energySiteData: {
      initial: null,
      filtered: null,
    },
    energyBalanceData: null,
    energyBalanceSiteData: {
      initial: null,
      filtered: null,
    },
    rawMaterialItems: null,
    rawMaterialData: null,
    rawMaterialSiteData: {
      initial: null,
      filtered: null,
    },
    models: null,
    modelsData: null,
    modelsSiteData: {
      initial: null,
      filtered: null,
    },
    equipmentsData: null,
    equipmentsSiteData: {
      initial: null,
      filtered: null,
    },
    variables: null,
    years: null,
    sites: null,
    diagrams: [],
    selectedYears: [],
    selectedYear: null,
    selectedSite: 'all',
    scenarioInformation,
    charts,
  },

  mutations: {
    selectSite(state, site) {
      state.selectedSite = site;

      const dataStates = [
        'scenarioYearlySiteData',
        'accountingItemSiteData',
        'processStepSiteData',
        'userSiteData',
        'useTypeSiteData',
        'energySiteData',
        'energyBalanceSiteData',
        'rawMaterialSiteData',
        'modelsSiteData',
        'equipmentsSiteData',
        'scenarioSiteData',
      ];
      dataStates.forEach((dataState) => {
        state[dataState].filtered = state[dataState].initial.filter(
          (row) => state.selectedSite === row.site,
        );
      });
    },

    setScenarioMeta(state, scenarioId) {
      state.processSteps = require(`@/assets/data/scenarios/${scenarioId}/meta/process_step_meta.json`);
      state.userItems = require(`@/assets/data/scenarios/${scenarioId}/meta/users_meta.json`);
      state.useTypeItems = require(`@/assets/data/scenarios/${scenarioId}/meta/use_types_meta.json`);
      state.rawMaterialItems = require(`@/assets/data/scenarios/${scenarioId}/meta/raw_material_meta.json`);
      state.models = require(`@/assets/data/scenarios/${scenarioId}/meta/models_meta.json`);
      state.sites = require(`@/assets/data/scenarios/${scenarioId}/meta/sites_meta.json`);
    },

    setScenarioYearlyData(state, scenarioId) {
      state.processStepData = require(`@/assets/data/scenarios/${scenarioId}/processStep_year.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );
      state.processStepSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/processStep_year_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      state.accountingItemData = require(`@/assets/data/scenarios/${scenarioId}/accountingItem_year.csv`).filter(
        (row) => state.selectedYears.includes(row.year),
      );

      state.accountingItemSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/accountingItem_year_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      state.userData = require(`@/assets/data/scenarios/${scenarioId}/user_year.csv`).filter(
        (row) => state.selectedYears.includes(row.year),
      );
      state.userSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/user_year_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      state.useTypeData = require(`@/assets/data/scenarios/${scenarioId}/use_year.csv`).filter(
        (row) => state.selectedYears.includes(row.year),
      );
      state.useTypeSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/use_year_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      state.energyData = require(`@/assets/data/scenarios/${scenarioId}/energy_year.csv`).filter(
        (row) => state.selectedYears.includes(row.year),
      );
      state.energySiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/energy_year_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      state.energyBalanceData = require(`@/assets/data/scenarios/${scenarioId}/energy_month.csv`).filter(
        (row) => state.selectedYears.includes(row.year),
      );
      state.energyBalanceSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/energy_month_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      state.rawMaterialData = require(`@/assets/data/scenarios/${scenarioId}/rawMaterial_year.csv`).filter(
        (row) => state.selectedYears.includes(row.year),
      );
      state.rawMaterialSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/rawMaterial_year_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      state.scenarioYearlyData = require(`@/assets/data/scenarios/${scenarioId}/variable_year.csv`).filter(
        (row) => state.selectedYears.includes(row.year),
      );
      state.scenarioYearlySiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/variable_year_site.csv`)
        .filter(
          (row) => state.selectedYears.includes(row.year),
        );

      try {
        const diagramModule = require(`@/assets/data/scenarios/${scenarioId}/diagrams.js`);
        state.diagrams = diagramModule.diagrams;
      } catch (e) {
        state.diagrams = [];
      }
    },

    setScenarioData(state, scenarioId) {
      state.modelsData = require(`@/assets/data/scenarios/${scenarioId}/model_allAlong.csv`);
      state.modelsSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/model_allAlong_site.csv`);

      state.equipmentsData = require(`@/assets/data/scenarios/${scenarioId}/equipments_allAlong.csv`);
      state.equipmentsSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/equipments_allAlong_site.csv`);

      state.scenarioData = require(`@/assets/data/scenarios/${scenarioId}/variable_allAlong.csv`);
      state.scenarioSiteData.initial = require(`@/assets/data/scenarios/${scenarioId}/variable_allAlong_site.csv`);
    },

    setProjectMetaData(state) {
      state.accountingItems = require('@/assets/data/scenarios/meta/accounting_item_meta.json');
      state.energyItems = require('@/assets/data/scenarios/meta/energy_meta.json');
      state.variables = require('@/assets/data/scenarios/meta/variables.json');
      state.years = require('@/assets/data/scenarios/meta/years.json');
      state.selectedYears = state.years;
      [state.selectedYear] = state.years;
    },

    selectYear(state, year) {
      state.selectedYear = year;
    },

    setSelectedYears(state, years) {
      state.selectedYears = [...years];
      [state.selectedYear] = state.selectedYears;
    },
  },

  actions: {
    loadScenarioData({ commit, state }, scenarioId) {
      state.selectedScenario = state.scenarioInformation.find(
        (scenario) => scenario.id.toString() === scenarioId.toString(),
      );
      state.selectedSite = 'all';
      commit('setScenarioMeta', scenarioId);
      commit('setScenarioYearlyData', scenarioId);
      commit('setScenarioData', scenarioId);
    },
  },
};
