<template>
    <v-chart
      class="chart"
      ref="echart"
      :option="option"
      :update-options="{ notMerge: true, lazyUpdate: true }"
      @legendselectchanged="legendItemSelection"
      autoresize
    />
    <DownloadButton class="download-button" @click="exportAsXlsx"/>
</template>

<script>
import XLSX from 'xlsx';
import 'echarts/lib/component/dataset';
import utilities from '../../../assets/mixins/utilities';
import DownloadButton from '../../DownloadButton.vue';

export default {
  name: 'ChartDataset',
  components: { DownloadButton },
  props: {
    title: String,
    unit: String,
    itemType: String,
    include: [Array, String],
    exclude: {
      type: Array,
      default: () => [],
    },
    chartType: [Array, String],
    stacks: [Array, String],
    variable: String,
    source: String,
    xValueType: String,
    tags: {
      type: Array,
      default: () => [],
    },
    colorScheme: Object,
  },

  data() {
    return {
      jsonData: [],
      option: {
        grid: {
          top: '2%',
          right: '27%',
          left: '0%',
          bottom: '2%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => this.tooltipFormatterConfig(params),
        },
        legend: {
          orient: 'vertical',
          left: '76%',
          top: '3%',
          bottom: '10%',
          textStyle: {
            width: '90',
            overflow: 'break',
          },
          type: 'scroll',
          height: '75%',
        },
        xAxis: {
          type: 'category',
          axisLine: { onZero: false },
        },
        yAxis: {
          axisLabel: {
            formatter: (value) => {
              if (Math.abs(value) >= 1000) {
                return utilities.unitConvert(value, this.unit);
              }
              return `${value} ${this.unit}`;
            },
          },
          max: (value) => (value.max < 0 ? Math.ceil(value.max) : null),
        },
        textStyle: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
      series: [],
      dataset: {
        source: [],
      },
      exportDataset: {
        source: [],
      },
      modelCodes: null,
    };
  },

  computed: {
    isAllSiteSelected() {
      return this.$store.state.data.selectedSite === 'all';
    },
    dataSource() {
      if (this.source === 'yearly_scenario') {
        return this.isAllSiteSelected
          ? this.$store.state.data.scenarioYearlyData
          : this.$store.state.data.scenarioYearlySiteData.filtered;
      }
      if (this.source === 'scenario') {
        return this.isAllSiteSelected
          ? this.$store.state.data.scenarioData
          : this.$store.state.data.scenarioSiteData.filtered;
      }
      if (this.source === 'yearly_process_step') {
        return this.isAllSiteSelected
          ? this.$store.state.data.processStepData
          : this.$store.state.data.processStepSiteData.filtered;
      }
      if (this.source === 'yearly_accounting_item') {
        return this.isAllSiteSelected
          ? this.$store.state.data.accountingItemData
          : this.$store.state.data.accountingItemSiteData.filtered;
      }
      if (this.source === 'yearly_energy') {
        return this.isAllSiteSelected
          ? this.$store.state.data.energyData
          : this.$store.state.data.energySiteData.filtered;
      }
      if (this.source === 'yearly_raw_material') {
        return this.isAllSiteSelected
          ? this.$store.state.data.rawMaterialData
          : this.$store.state.data.rawMaterialSiteData.filtered;
      }
      if (this.source === 'yearly_user') {
        return this.isAllSiteSelected
          ? this.$store.state.data.userData
          : this.$store.state.data.userSiteData.filtered;
      }
      if (this.source === 'yearly_use') {
        return this.isAllSiteSelected
          ? this.$store.state.data.useTypeData
          : this.$store.state.data.useTypeSiteData.filtered;
      }

      return this.isAllSiteSelected
        ? this.$store.state.data.modelsData
        : this.$store.state.data.modelsSiteData.filtered;
    },
    seriesItems() {
      let seriesItems;

      switch (this.itemType) {
        case 'variable':
          seriesItems = this.$store.state.data.variables;
          break;
        case 'process_step':
          seriesItems = this.$store.state.data.processSteps;
          break;
        case 'user':
          seriesItems = this.$store.state.data.userItems;
          break;
        case 'use':
          seriesItems = this.$store.state.data.useTypeItems;
          break;
        case 'energy':
          seriesItems = this.$store.state.data.energyItems;
          break;
        case 'raw_material':
          seriesItems = this.$store.state.data.rawMaterialItems;
          break;
        default:
          seriesItems = this.$store.state.data.accountingItems;
          break;
      }

      return seriesItems;
    },
    scenarioLabel() {
      return this.$store.state.data.scenarioInformation
        .find((info) => info.id.toString() === this.$route.params.scenarioId).label;
    },
    colorPalette() {
      return this.include.length > 5 || typeof this.include !== 'string'
        ? this.colorScheme.colorPalette5
        : this.colorScheme.colorPalette10;
    },
  },

  watch: {
    dataSource(newValue) {
      this.transformData(newValue, this.dataset, false);
    },
    seriesItems(newValue) {
      this.transformSeriesItem(newValue);
    },
  },

  methods: {
    transformSeriesItem(seriesItems) {
      this.series = [];

      if (this.chartType === 'pie') {
        Object.assign(this.option.tooltip, {
          trigger: 'item',
        });
        this.series.push({
          type: this.chartType,
          encode: {
            itemName: this.itemType,
            value: 'value',
            tooltip: 'value',
          },
          radius: '80',
          center: ['37%', '50%'],
          itemStyle: {
            borderRadius: 8,
          },
          color: [],
        });
      } else {
        seriesItems.forEach((item) => {
          if ((this.include === 'all' && !this.exclude.includes(item.code)) || this.include.includes(item.code)) {
            const itemIndex = this.include.indexOf(item.code);
            const chartType = typeof this.chartType === 'string'
              ? this.chartType
              : this.chartType[itemIndex];
            const stack = Array.isArray(this.stacks) ? this.stacks[itemIndex] : this.stacks;
            const seriesRow = {
              type: chartType,
              stack,
              color: item.color,
              seriesLayoutBy: 'row',
            };
            if (chartType === 'waterfall') {
              Object.assign(seriesRow, {
                type: 'bar',
                emphasis: {
                  focus: 'series',
                },
                animationDelay(idx) {
                  return idx * 10 + itemIndex * 50;
                },
              });
            }
            this.series.push(seriesRow);
          }
        });
      }
      if (this.chartType === 'waterfall') {
        Object.assign(this.option, {
          animationEasing: 'elasticOut',
          animationDelayUpdate(idx) {
            return idx * 15;
          },
        });
      }
      if (this.xValueType === 'model') {
        Object.assign(this.option.xAxis, {
          axisLabel: {
            interval: 0,
            width: '60',
            overflow: 'truncate',
          },
        });
      }
      this.option.series = this.series;
    },

    transformData(data, dataset, exportUse) {
      let xValues;
      if (this.xValueType === 'year') {
        if (exportUse) xValues = this.$store.state.data.years;
        else xValues = this.$store.state.data.selectedYears;
      } else if (this.xValueType === 'model') {
        xValues = this.$store.state.data.models.map((model) => (model.label));
        this.modelCodes = this.$store.state.data.models.map((model) => (model.code));
      } else {
        xValues = ['value'];
        this.option.xAxis.show = false;
      }
      dataset.source = [];
      dataset.source[0] = [this.itemType, ...xValues];

      this.seriesItems.forEach((item) => {
        if ((this.include === 'all' && !this.exclude.includes(item.code)) || this.include.includes(item.code)) {
          const serieData = [item.label];
          if (this.xValueType) {
            for (let i = 1; i < dataset.source[0].length; i += 1) {
              const value = data.find((row) => (row[this.itemType] === item.code)
                && (!this.variable || this.variable === row.variable)
                && (this.xValueType === 'year'
                  ? (dataset.source[0][i] === row[this.xValueType])
                  : (this.modelCodes[i - 1] === row[this.xValueType])));
              serieData.push(value ? value.value : undefined);
            }
          } else {
            data.forEach((row) => {
              if ((row[this.itemType] === item.code)
            && (!this.variable || this.variable === row.variable)) {
                serieData.push(row.value);
              }
            });
          }
          dataset.source.push(serieData);
        }
      });
    },

    prepareExportJsonData() {
      this.transformData(this.dataSource, this.exportDataset, true);

      this.jsonData = [];
      for (let i = 1; i < this.exportDataset.source.length; i += 1) {
        for (let j = 1; j < this.exportDataset.source[i].length; j += 1) {
          const jsonDataItem = {
            scenario_name: this.scenarioLabel,
            site_name: this.$store.state.data.selectedSite,
            graph_name: this.title,
            option: this.exportDataset.source[0][0] === 'variable' ? 'N/A' : this.dataset.source[0][0],
            legend_item: this.exportDataset.source[i][0],
            year: this.exportDataset.source[0][j],
            value: this.exportDataset.source[i][j],
            unit: this.unit,
          };
          this.jsonData.push(jsonDataItem);
        }
      }
    },

    exportAsXlsx() {
      this.prepareExportJsonData();
      const fileName = `${this.title}_${new Date().toISOString().slice(0, 10).replaceAll('-', '')}.xlsx`.replace(/[|&;$%@"<>()+, ]/g, '_');
      const ws = XLSX.utils.json_to_sheet(this.jsonData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Graph data');
      XLSX.writeFile(wb, fileName);
    },

    tooltipFormatterConfig(params) {
      if (this.chartType === 'pie') {
        const value = params.value[1] !== undefined
          ? params.value[1].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
          : 'N/A';
        return `${params.marker + params.value[0]}: ${value}${value !== 'N/A' ? this.unit : ''} (${params.percent}%)`;
      }
      if (this.stacks === 'single') {
        let total = 0;
        let msg = '';
        params.forEach((param) => {
          const value = param.value[param.seriesIndex + 1] !== undefined
            ? param.value[param.seriesIndex + 1]
            : 'N/A';
          msg += `<div><span>${param.marker + param.seriesName}</span> <span style="float:right;margin-left:20px">${value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} ${value !== 'N/A' ? this.unit : ''}</span></div>`;
          if (value !== 'N/A') total += value;
          else total = 'N/A';
        });
        msg = `<div style="font-weight:900;">${params[0].axisValueLabel}</div><div style="margin:5px 0"><span>Total</span> <span style="font-weight:900;float:right;margin-left:20px">${total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} ${total !== 'N/A' ? this.unit : ''}</span></div>${msg}`;
        return msg;
      }
      let msg = `<div style="font-weight:900;margin-bottom: 5px">${params[0].axisValueLabel}</div>`;
      params.forEach((param) => {
        const value = param.value[param.seriesIndex + 1] !== undefined
          ? param.value[param.seriesIndex + 1].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
          : 'N/A';
        msg += `<div><span>${param.marker + param.seriesName}</span> <span style="float:right;margin-left:20px">${value} ${value !== 'N/A' ? this.unit : ''}</span></div>`;
      });
      return msg;
    },

    // Prevent deselection if all other items are deselected
    legendItemSelection(params) {
      let selectedCount = 0;
      Object.keys(params.selected).forEach((key) => {
        if (params.selected[key]) selectedCount += 1;
      });

      if (selectedCount === 0) {
        const chart = this.$refs.echart;
        chart.setOption({ animation: false });

        Object.keys(params.selected).forEach((name) => {
          chart.dispatchAction({
            type: 'legendSelect',
            name,
          });
        });

        chart.setOption({ animation: true });
      }
    },
  },

  mounted() {
    this.option.dataset = this.dataset;
    this.option.color = this.colorPalette;
    this.transformSeriesItem(this.seriesItems);
    this.transformData(this.dataSource, this.dataset, false);
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 20rem;
  padding: 0rem 1rem 1rem 1rem;
  font-family: inherit;
}
.download-button{
  position: absolute;
  right: 2%;
  bottom: 1%;
}
</style>
