<template>
  <router-link
    :to="{ name: 'Overview' }"
    class="navbar-item mx-1"
  >
    Overview
  </router-link>

  <router-link
    :to="{ name: 'Map' }"
    class="navbar-item mx-1"
  >
    Map
  </router-link>

  <div class="navbar-item has-dropdown mx-1" :class="{'is-active': showDropdown}">
    <a
      class="navbar-link"
      id="dropdown-trigger"
      @click="showDropdown = !showDropdown"
      v-click-away="closeDropdown"
    >
      Data
    </a>

    <div class="navbar-dropdown">
      <router-link v-for="dataPageChild in dataPageChildren"
                   :key="dataPageChild"
                   :to="{ name: dataPageChild }"
                   class="navbar-item"
                   :class="{'is-active': $route.name === dataPageChild}"
                   @click="showDropdown = false"
      >
       {{ dataPageChild }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScenarioMainNavigation',

  data() {
    return {
      showDropdown: false,
      dataPageChildren: ['Economics', 'Supply chain', 'Environmental impact'],
    };
  },

  methods: {
    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
