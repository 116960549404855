<template>
  <div class="container">
    List of scenarios
    <ul class="mt-3">
      <li v-for="scenario in scenarios" :key="scenario.id">
        <router-link
          :to="{
            name: 'Overview',
            params: { scenarioId: scenario.id},
          }"
        >
          {{ scenario.label }}
        </router-link>
      </li>
    </ul>
  </div>

  <BackToTop/>
</template>

<script>
import BackToTop from '../components/BackToTop.vue';

export default {
  name: 'Scenarios',
  components: { BackToTop },
  data() {
    return {
      scenarios: null,
    };
  },

  created() {
    this.scenarios = this.$store.state.data.scenarioInformation;
  },
};
</script>

<style lang="scss" scoped>
</style>
