import { createRouter, createWebHistory } from 'vue-router';
import Documentation from '@/views/Documentation.vue';
import Scenarios from '../views/Scenarios.vue';
import ScenarioContainer from '../views/Scenario/ScenarioContainer.vue';
import Overview from '../views/Scenario/Overview.vue';
import Map from '../views/Scenario/Map.vue';
import Data from '../views/Scenario/Data.vue';
import Economics from '../views/Scenario/Data/Economics.vue';
import SupplyChain from '../views/Scenario/Data/SupplyChain.vue';
import EnvironmentalImpact from '../views/Scenario/Data/EnvironmentalImpact.vue';
import ScenarioMainNavigation from '../components/Scenario/ScenarioMainNavigation.vue';
import NotFound from '../components/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/scenarios',
  },
  {
    path: '/scenarios',
    name: 'Scenarios',
    component: Scenarios,
  },
  {
    path: '/scenarios/:scenarioId',
    components: {
      default: ScenarioContainer,
      mainMenu: ScenarioMainNavigation,
    },
    children: [
      {
        path: '',
        name: 'Overview',
        component: Overview,
      },
      {
        path: 'map',
        name: 'Map',
        component: Map,
      },
      {
        path: 'data',
        name: 'Data',
        component: Data,
        children: [
          {
            path: '',
            redirect: { name: 'Economics' },
          },
          {
            name: 'Economics',
            path: 'economics',
            component: Economics,
          },
          {
            name: 'Supply chain',
            path: 'supply-chain',
            component: SupplyChain,
          },
          {
            name: 'Environmental impact',
            path: 'environmental-impact',
            component: EnvironmentalImpact,
          },
        ],
      },
    ],
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page not found',
    component: NotFound,
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    return { el: to.hash, behavior: 'smooth' };
  }
  if (savedPosition) {
    return savedPosition;
  }
  return { left: 0, top: 0 };
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
});

export default router;
