<template>
  <SelectionDropdownWrapper
    :isMultipleSelection="true"
    v-on:closeDropdown="resetYearsSelection"
  >
    <template v-slot:trigger-text>Years</template>
    <template v-slot:content>
      <div
        @click="selectAllYears"
        class="dropdown-item select-all-years"
      >
        All
        <span v-if="allYearsAreSelected">
          <i class="fas fa-check"/>
        </span>
      </div>
      <hr class="navbar-divider"/>
      <MultipleSelectionDropdownItem
        v-for="year in $store.state.data.years"
        :key="year"
        :label="year.toString()"
        :is-selected="selectedYears.includes(year)"
        @click="addYearToSelection(year)"
      />
    </template>
  </SelectionDropdownWrapper>
  <button class="button ml-4" @click="applyYearSelection">Save</button>
</template>

<script>
import MultipleSelectionDropdownItem from '@/components/MultipleSelectionDropdownItem.vue';
import SelectionDropdownWrapper from '@/components/SelectionDropdownWrapper.vue';

export default {
  name: 'YearSelectionDropdown',

  components: {
    MultipleSelectionDropdownItem,
    SelectionDropdownWrapper,
  },

  data() {
    return {
      selectedYears: [...this.$store.state.data.selectedYears],
    };
  },
  computed: {
    allYearsAreSelected() {
      return this.selectedYears.length === this.$store.state.data.years.length;
    },
  },

  methods: {
    addYearToSelection(year) {
      const yearIndex = this.selectedYears.indexOf(year);
      if (yearIndex !== -1) {
        this.selectedYears.splice(yearIndex, 1);
      } else {
        this.selectedYears.push(year);
        this.selectedYears.sort();
      }
    },

    selectAllYears() {
      this.selectedYears = [...this.$store.state.data.years];
    },

    applyYearSelection() {
      this.$store.commit('setSelectedYears', this.selectedYears);
      if (this.$store.state.data.selectedScenario) {
        this.$store.commit('setScenarioYearlyData', this.$store.state.data.selectedScenario.id);
        this.$store.commit('selectSite', this.$store.state.data.selectedSite);
      }
    },

    resetYearsSelection() {
      this.selectedYears = [...this.$store.state.data.selectedYears];
    },
  },
};
</script>

<style lang="scss" scoped>
.select-all-years:hover {
  background-color: $primary-light;
}

button {
  background-color: $applicationBlue !important;
  color: white !important;
}
</style>
