import { createApp } from 'vue';

import ECharts from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  BarChart, LineChart, ScatterChart, PieChart,
} from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
} from 'echarts/components';

import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

import VueClickAway from 'vue3-click-away';

import App from './App.vue';
import router from './router';
import store from './store';

library.add(fas);
library.add(far);
dom.watch();

const app = createApp(App);

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  ScatterChart,
  PieChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
]);

app.component('v-chart', ECharts);

app.use(router);
app.use(store);
app.use(VueClickAway);

app.mount('#app');
