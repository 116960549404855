<template>
  <nav
    id="navbar"
    class="navbar has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <NavbarDropdownWrapper :isLeftSide="true">
        <template v-slot:icon>
          <figure class="image is-24x24">
            <img src="../assets/images/icons/dots.svg" alt="All apps" />
          </figure>
        </template>
        <template v-slot:content>
          <TheAllAppsMenu />
        </template>
      </NavbarDropdownWrapper>

      <div class="navbar-item ml-1 mr-3 logo">
        <router-link :to="{ name: 'Home' }" class="home-link" />
      </div>
      <a
        role="button"
        id="navigationBurger"
        class="navbar-burger"
        :class="{ 'is-active': isNavBurgerActive }"
        aria-label="menu"
        aria-expanded="false"
        data-target="main-navigation"
        @click="toggle()"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="main-navigation"
      class="navbar-menu"
      :class="{ 'is-active': isNavBurgerActive }"
    >
      <div class="navbar-start ml-6">
        <router-view name="mainMenu" />
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="field">
            <p class="control has-icons-left">
              <input
                type="text"
                class="input is-rounded search-bar"
                placeholder="Search"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-search" />
              </span>
            </p>
          </div>
        </div>

        <NavbarDropdownWrapper
          v-for="navbarTool in navbarTools"
          :key="navbarTool.title"
        >
          <template v-slot:icon>
            <figure class="image is-24x24">
              <img
                :src="require(`@/assets/images/${navbarTool.icon}`)"
                alt="dropdown.name"
              />
            </figure>
          </template>
          <template v-slot:content>
            <h2 class="title">{{ navbarTool.title }}</h2>
            <component :is="navbarTool.component" />
          </template>
        </NavbarDropdownWrapper>
      </div>
    </div>
  </nav>
</template>

<script>
import NavbarDropdownWrapper from '@/components/NavbarDropdownWrapper.vue';
import TheDocumentationDropdown from '@/components/TheDocumentationDropdown.vue';
import YearSelectionDropdown from './Scenario/Data/YearSelectionDropdown.vue';
import TheAllAppsMenu from './TheAllAppsMenu.vue';

export default {
  name: 'TheNavbar',
  components: {
    TheAllAppsMenu,
    YearSelectionDropdown,
    NavbarDropdownWrapper,
    TheDocumentationDropdown,
  },

  data() {
    return {
      isAllAppsMenuActive: false,
      isNavBurgerActive: false,
      navbarTools: [
        {
          title: 'Configuration',
          icon: 'icons/settings.svg',
          component: YearSelectionDropdown.name,
        },
        {
          title: 'Documentation',
          icon: 'icons/documentation.svg',
          component: TheDocumentationDropdown.name,
        },
        {
          title: 'Updates',
          icon: 'icons/notification.svg',
          component: null,
        },
        {
          title: 'User',
          icon: 'icons/profile.svg',
          component: null,
        },
      ],
    };
  },

  methods: {
    toggle() {
      this.isNavBurgerActive = !this.isNavBurgerActive;
    },

    closeSubMenus() {
      this.isAllAppsMenuActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#navbar {
  z-index: 100;
}

.logo {
  width: 90px;
  background: url("../assets/images/tedhy_logo.svg") no-repeat center center;

  a {
    width: 100%;
    height: 100%;
  }
}

.search-bar {
  min-width: 300px;
  transition: min-width .5s;
}
.search-bar:focus {
  min-width: 340px;
}
</style>
