<template>
  <router-view/>
</template>

<script>
export default {
  name: 'Data',
};
</script>

<style lang="scss">
</style>
