<template>
  <article class="container py-3">
    <h1 class="title">Documentation</h1>
    <section>
      <p>
        Want to learn more about how TedHy gets its results? Our documentation
        describes and explains how it works! + we also have all the links to our
        sources and references for you to check out. So we'll have 3 boxes that
        will include their own hyperlinks to pages in the proper documentation
        site
      </p>
    </section>
    <section id="technology">
      <h2 class="title is-4">About Technology</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec bibendum
        lacinia ornare. Nullam nec mi posuere, condimentum erat quis, sodales
        lectus. Sed et orci non urna viverra porttitor sed at mi. Duis molestie
        risus dui, vel mollis ipsum gravida eget. Mauris imperdiet lorem arcu,
        et accumsan libero malesuada eget. Vestibulum porta mollis lacus vel
        interdum. Donec in justo ullamcorper, vehicula arcu ut, posuere dui.
        Proin varius ex arcu, nec maximus erat luctus id. Donec nunc risus,
        semper ut imperdiet sed, dictum sit amet diam. Ut pulvinar rutrum metus,
        a dictum justo faucibus eget. Nam blandit viverra aliquam. Nam diam
        erat, luctus convallis interdum vitae, venenatis a purus. Morbi
        tincidunt, erat vehicula vulputate lacinia, ante felis tincidunt justo,
        ac varius ligula ante eget arcu. Quisque interdum, libero ut auctor
        viverra, sem arcu maximus magna, vel semper sem ligula a libero.
      </p>
    </section>
    <section id="economics">
      <h2 class="title is-4">About Economics</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec bibendum
        lacinia ornare. Nullam nec mi posuere, condimentum erat quis, sodales
        lectus. Sed et orci non urna viverra porttitor sed at mi. Duis molestie
        risus dui, vel mollis ipsum gravida eget. Mauris imperdiet lorem arcu,
        et accumsan libero malesuada eget. Vestibulum porta mollis lacus vel
        interdum. Donec in justo ullamcorper, vehicula arcu ut, posuere dui.
        Proin varius ex arcu, nec maximus erat luctus id. Donec nunc risus,
        semper ut imperdiet sed, dictum sit amet diam. Ut pulvinar rutrum metus,
        a dictum justo faucibus eget. Nam blandit viverra aliquam. Nam diam
        erat, luctus convallis interdum vitae, venenatis a purus. Morbi
        tincidunt, erat vehicula vulputate lacinia, ante felis tincidunt justo,
        ac varius ligula ante eget arcu. Quisque interdum, libero ut auctor
        viverra, sem arcu maximus magna, vel semper sem ligula a libero.
      </p>
    </section>
    <section id="environment">
      <h2 class="title is-4">About Environment</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec bibendum
        lacinia ornare. Nullam nec mi posuere, condimentum erat quis, sodales
        lectus. Sed et orci non urna viverra porttitor sed at mi. Duis molestie
        risus dui, vel mollis ipsum gravida eget. Mauris imperdiet lorem arcu,
        et accumsan libero malesuada eget. Vestibulum porta mollis lacus vel
        interdum. Donec in justo ullamcorper, vehicula arcu ut, posuere dui.
        Proin varius ex arcu, nec maximus erat luctus id. Donec nunc risus,
        semper ut imperdiet sed, dictum sit amet diam. Ut pulvinar rutrum metus,
        a dictum justo faucibus eget. Nam blandit viverra aliquam. Nam diam
        erat, luctus convallis interdum vitae, venenatis a purus. Morbi
        tincidunt, erat vehicula vulputate lacinia, ante felis tincidunt justo,
        ac varius ligula ante eget arcu. Quisque interdum, libero ut auctor
        viverra, sem arcu maximus magna, vel semper sem ligula a libero.
      </p>
    </section>
  </article>
</template>

<script>
export default {
  name: 'Documentation',
};
</script>

<style lang="scss" scoped>
section {
  padding: 1.5rem 1rem;
}
</style>
