<template>
  <div>
    <TheActionMenu/>
    <div class="columns is-mobile is-gapless">
      <aside class="column is-narrow sidebar">
        <SidebarMenu
          :menu="scenarios"
          :relative="true"
          :hideToggle="true"
          :widthCollapsed="'0'"
          :width="'350px'"
          :collapsed="!isSidebarMenuActive"
          @item-click="conditionalCloseSidebarMenu"
          :theme="'white-theme'"
          v-click-away="onClickAway"
        />
      </aside>
      <div class="column">
        <div class="title is-3 p-2">{{ $route.name }}</div>
        <router-view />
      </div>
    </div>
  </div>

  <BackToTop />
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu';
import TheActionMenu from '../../components/Scenario/Data/TheActionMenu.vue';
import BackToTop from '../../components/BackToTop.vue';

export default {
  name: 'ScenarioContainer',

  components: {
    BackToTop,
    TheActionMenu,
    SidebarMenu,
  },

  created() {
    this.$store.dispatch('loadScenarioData', this.$route.params.scenarioId);
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.scenarioId !== from.params.scenarioId) {
      this.$store.dispatch('loadScenarioData', to.params.scenarioId.toString());
    }
    next();
  },

  computed: {
    scenarios() {
      return [{
        header: 'Scenarios',
        hiddenOnCollapse: true,
      }].concat(this.$store.state.data.scenarioInformation.map(
        (scenario) => ({
          title: scenario.label,
          id: scenario.id,
          href: {
            name: this.$route.name,
            params: { scenarioId: scenario.id },
          },
        }),
      ));
    },

    isSidebarMenuActive: {
      get() {
        return this.$store.state.isSidebarMenuActive;
      },
      set(isSidebarMenuActive) {
        this.$store.commit('setSidebarMenuActive', isSidebarMenuActive);
      },
    },
  },

  methods: {
    conditionalCloseSidebarMenu() {
      if (this.isSidebarMenuActive) this.isSidebarMenuActive = false;
    },
    onClickAway(event) {
      if (event.target.alt !== 'burger') this.conditionalCloseSidebarMenu();
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  position: sticky;
  vertical-align: top;
  height: 100vh;
  overflow-y: auto;
  top: 55px;
}

.v-sidebar-menu .vsm--item {
  white-space: unset;
}

.v-sidebar-menu .vsm--item:hover {
  background-color: $primary-light;
}
</style>
