<template>
  <div class="is-relative title-bar">
    <div class="handle title-bar"
         :class="{'movable': isMovable}"
    >
      <div class="subtitle p-2 pl-4">
        <span class="is-clickable">
          {{ title }}
        </span>
        <span class="ml-3">
          <HoverDropdownWrapper>
            <template #trigger>
              <IconBase
                class="ml-3 chart-dropdown-trigger"
                aria-haspopup="true"
                aria-controls="dropdown-menu4"
                icon-name="information"
                viewBox="0 0 620.97 614"
              >
                <IconInformation />
              </IconBase>
            </template>
            <template #content>
              <p class="description-text">{{description}}</p>
            </template>
          </HoverDropdownWrapper>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/icons/IconBase.vue';
import IconInformation from '@/components/icons/IconInformation.vue';
import HoverDropdownWrapper from '../../HoverDropdownWrapper.vue';

export default {
  name: 'ChartTitleBar',

  props: {
    title: String,
    description: String,
    isMovable: Boolean,
  },

  components: {
    IconBase,
    IconInformation,
    HoverDropdownWrapper,
  },

};
</script>

<style lang="scss" scoped>
.title-bar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.handle {
  background-color: #f9fbfd;
  border-bottom: whitesmoke thin;
}
.movable {
  cursor: move;
}

.description-text {
  width: 20rem;
}
</style>
