<template>
  <div class="column" :class="{ 'is-6': half }">
    <div class="box p-0 is-relative">
      <ChartTitleBar
        :title="chartGroup.title"
        :description="chart.description"
        :isMovable="half"
      />
      <div class="level mb-4 pl-4">
        <div class="level-left">
          <div class="level-item">
            <div class="tabs is-small" v-if="!isSingleTab">
              <ChartToggleTabs
                v-model:selectedTab="selectedTab"
                :tabs="chartGroup.tabs"
              />
            </div>
          </div>
        </div>
        <div class="level-right pr-2 pt-1">
          <HoverDropdownWrapper
            class="level-item"
            is-right-aligned
          >
            <template #trigger>
              <IconBase
                  class="chart-dropdown-trigger"
                  height="30"
                  width="30"
                  icon-name="information"
                  viewBox="0 0 614.46 614.46"
                >
                  <IconInput v-if="chartGroup.tags.includes('input')" />
                  <IconOutput v-else />
                </IconBase>
            </template>
            <template #content>
              <p v-if="chartGroup.tags.includes('input')">Input</p>
              <p v-else>Output</p>
            </template>
          </HoverDropdownWrapper>
        </div>
      </div>
      <ChartEnergyBalance
        v-if="isEnergyBalance"
        :title="chartGroup.title"
        :unit="chart.unit"
        :variable="chart.variable"
        :currentYear="currentYear"
      />
      <ChartDataset
        v-else
        :tags="chartGroup.tags"
        :key="chart.code"
        :title="chartGroup.title"
        :unit="chart.unit"
        :item-type="chart.itemType"
        :include="chart.include"
        :exclude="chart.exclude"
        :chart-type="chart.chartType"
        :stacks="chart.stacks"
        :variable="chart.variable"
        :source="chart.source"
        :xValueType="chart.xValueType"
        :color-scheme="colorScheme"
      />
    </div>
  </div>
</template>

<script>
import ChartDataset from '@/components/Scenario/Data/ChartDataset.vue';
import ChartEnergyBalance from '@/components/Scenario/Data/ChartEnergyBalance.vue';
import ChartToggleTabs from '@/components/Scenario/Data/ChartToggleTabs.vue';
import ChartTitleBar from '@/components/Scenario/Data/ChartTitleBar.vue';
import IconBase from '@/components/icons/IconBase.vue';
import IconInput from '@/components/icons/IconInput.vue';
import IconOutput from '@/components/icons/IconOutput.vue';
import HoverDropdownWrapper from '../../HoverDropdownWrapper.vue';

export default {
  name: 'ChartGroup',

  components: {
    HoverDropdownWrapper,
    ChartDataset,
    ChartEnergyBalance,
    ChartToggleTabs,
    ChartTitleBar,
    IconBase,
    IconInput,
    IconOutput,
  },

  props: {
    chartGroup: Object,
    half: Boolean,
    isEnergyBalance: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedTab: this.chartGroup.defaultTab,
      charts: this.$store.state.data.charts,
    };
  },

  computed: {
    isSingleTab() {
      return typeof this.chartGroup.tabs === 'string';
    },
    chart() {
      const chartCode = this.chartGroup.chartList
        .find((chart) => (this.isSingleTab || chart.tab === this.selectedTab)).code;
      return this.charts.find((chart) => chart.code === chartCode);
    },
    currentYear() {
      return this.$store.state.data.selectedYear;
    },
    colorScheme() {
      return this.selectedTab
        ? this.$store.state.graphColorSchemes[
          this.chartGroup.tabs.indexOf(this.selectedTab)
        ]
        : this.$store.state.graphColorSchemes[0];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
