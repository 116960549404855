<template>
  <div v-if="code" class="mermaid" ref="mermaid">{{ code }}</div>
  <div v-else class="notification is-warning">No PFD is available.</div>
</template>

<script>
import { nextTick } from 'vue';
import mermaid from 'mermaid';

export default {
  name: 'TheFlowDiagram',

  computed: {
    code() {
      const year = this.$store.state.data.selectedYear;
      const site = this.$store.state.data.selectedSite;
      const diagram = this.$store.state.data.diagrams
        .find((d) => d.year === year && d.site === site);
      if (diagram) {
        return diagram.value;
      }
      return null;
    },
  },

  methods: {
    updateDiagram() {
      if (!this.code) {
        return;
      }
      const container = this.$refs.mermaid;
      const insertSvg = (svgCode) => {
        container.innerHTML = svgCode;
      };
      mermaid.render('pfd', this.code, insertSvg, container);
    },
  },

  async mounted() {
    mermaid.initialize({
      startOnLoad: false,
      securityLevel: 'loose',
      fontSize: 10,
      er: {
        fontSize: 8,
      },
      flowchart: {
        useMaxWidth: true,
      },
    });
    await nextTick();
    this.updateDiagram();
    // Ugly fix to ensure links to anchors work
    this.updateDiagram();
  },

  updated() {
    this.updateDiagram();
  },
};
</script>

<style>
.mermaid .label {
  font-weight: normal;
}
</style>
