<template>
  <div
    class="dropdown-item is-clickable"
    @click="selectItem()"
  >
    {{ label }}
    <span v-if="isSelected">
      <i class="fas fa-check"/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SingleSelectionDropdownItem',

  props: {
    label: String,
    value: String,
    variableName: String,
    callbackName: String,
  },

  computed: {
    isSelected() {
      return this.$store.state.data[this.variableName] === this.value;
    },
  },

  methods: {
    selectItem() {
      this.$store.commit(this.callbackName, this.value);
    },
  },

};
</script>

<style lang="scss" scoped>
.dropdown-item:hover {
  background-color: $primary-light;
}
</style>
