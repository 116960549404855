<template>
  <ul class="steps is-thin has-content-centered">
    <TimelineStep v-for="year in years" :key="year" :year="year"/>
  </ul>
</template>

<script>
import TimelineStep from './TimelineStep.vue';

export default {
  name: 'TheTimeline',

  components: { TimelineStep },

  computed: {
    years() {
      return this.$store.state.data.selectedYears;
    },
  },
};
</script>

<style scoped>
</style>
