<template>
  <div>
    <div class="box p-0">
      <chart-title-bar
        :description="description"
        :title="title"
      />
      <div class="has-text-right pt-2 pr-2">
        <HoverDropdownWrapper is-right-aligned>
          <template #trigger>
            <IconBase
              class="chart-dropdown-trigger"
              height="30"
              width="30"
              icon-name="information"
              viewBox="0 0 614.46 614.46"
            >
              <IconInput v-if="tags.includes('input')" />
              <IconOutput v-else />
            </IconBase>
          </template>
          <template #content>
            <p v-if="tags.includes('input')">Input</p>
            <p v-else>Output</p>
          </template>
        </HoverDropdownWrapper>
      </div>
      <div class="table-container p-2">
        <table class="table is-fullwidth has-text-centered is-hoverable">
          <thead class="has-background-light">
          <tr>
            <th>Variables</th>
            <th>Units</th>
            <th v-for="(year, i) in $store.state.data.selectedYears"
                :key="i">{{ year }}</th>
          </tr>
          </thead>
          <tfoot>
          </tfoot>
          <tbody>
          <tr v-for="(dataItem, i) in tableContent.filter((content) => content.values.length)"
              :key="i"
              :class="{'is-selected': selectedRows.has(i)}"
              v-on:click="select(i)"
          >
            <td
              class="has-text-left"
            ><b>{{ dataItem.label }}</b></td>
            <td
            >
              <b>{{ dataItem.unit }}</b>
            </td>
            <td
              v-for="(item, i) in dataItem.values"
              :key="i"
            >{{ convertUnits(item.value) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="p-2 has-text-right">
        <DownloadButton @click="exportAsXlsx(formatDataToExport(tableContent))"/>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import IconBase from '@/components/icons/IconBase.vue';
import IconInput from '@/components/icons/IconInput.vue';
import IconOutput from '@/components/icons/IconOutput.vue';
import utilities from '../../../assets/mixins/utilities';
import DownloadButton from '../../DownloadButton.vue';
import HoverDropdownWrapper from '../../HoverDropdownWrapper.vue';
import ChartTitleBar from './ChartTitleBar.vue';

export default {
  name: 'YearlyDataTable',
  components: {
    ChartTitleBar,
    HoverDropdownWrapper,
    DownloadButton,
    IconBase,
    IconInput,
    IconOutput,
  },
  props: {
    id: String,
    title: String,
    include: [Array, String],
    exclude: {
      type: Array,
      default: () => [],
    },
    itemType: String,
    source: String,
    variable: String,
    description: String,
    tags: Array,
  },

  data() {
    return {
      selectedRows: new Set(),
    };
  },
  computed: {
    legendItems() {
      if (this.itemType.includes('model')) return this.$store.state.data.models;
      if (this.source.includes('yearly_process_step')) return this.$store.state.data.processSteps;
      if (this.source.includes('yearly_accounting_item')) return this.$store.state.data.accountingItems;
      if (this.source.includes('yearly_user')) return this.$store.state.data.userItems;
      if (this.source.includes('yearly_use')) return this.$store.state.data.useTypeItems;
      return this.$store.state.data.variables;
    },
    itemsData() {
      if (this.source.includes('model')) {
        return this.$store.state.data.selectedSite === 'all'
          ? this.$store.state.data.modelsData
          : this.$store.state.data.modelsData.filtered;
      }
      if (this.source.includes('yearly_process_step')) {
        return this.$store.state.data.selectedSite === 'all'
          ? this.$store.state.data.processStepData
          : this.$store.state.data.processStepSiteData.filtered;
      }
      if (this.source.includes('yearly_accounting_item')) {
        return this.$store.state.data.selectedSite === 'all'
          ? this.$store.state.data.accountingItemData
          : this.$store.state.data.accountingItemSiteData.filtered;
      }
      if (this.source.includes('yearly_user')) {
        return this.$store.state.data.selectedSite === 'all'
          ? this.$store.state.data.userData
          : this.$store.state.data.userSiteData.filtered;
      }
      if (this.source.includes('yearly_use')) {
        return this.$store.state.data.selectedSite === 'all'
          ? this.$store.state.data.useTypeData
          : this.$store.state.data.useTypeSiteData.filtered;
      }
      if (this.source.includes('yearly_scenario')) {
        return this.$store.state.data.selectedSite === 'all'
          ? this.$store.state.data.scenarioYearlyData
          : this.$store.state.data.scenarioYearlySiteData.filtered;
      }
      return this.$store.state.data.selectedSite === 'all'
        ? this.$store.state.data.scenarioData
        : this.$store.state.data.scenarioSiteData.filtered;
    },

    tableContent() {
      return this.legendItems
        .filter((item) => this.include === 'all' || this.include.includes(item.code))
        .map((legendItem) => ({
          label: legendItem.label,
          unit: legendItem.unit,
          values: this.itemsData
            .filter((itemData) => itemData[this.itemType] === legendItem.code)
            .map((data) => ({
              value: data.value,
              year: data.year,
            })),
        }));
    },
  },

  methods: {
    convertUnits(value) {
      return utilities.numeralFormat(value);
    },

    select(rowId) {
      if (this.selectedRows.has(rowId)) this.selectedRows.delete(rowId);
      else this.selectedRows.add(rowId);
    },

    formatDataToExport() {
      const formatData = [];
      this.tableContent.forEach((content) => {
        content.values.forEach((value) => formatData.push(
          {
            scenario_name: this.$store.state.data.selectedScenario.label,
            site_name: 'all',
            table_name: this.title,
            option: this.itemType,
            legend_item: content.label,
            year: value.year,
            value: value.value,
            unit: content.unit,
          },
        ));
      });
      return formatData;
    },

    exportAsXlsx(dataToExport) {
      const fileName = `${this.title}_${new Date()
        .toISOString()
        .slice(0, 10)
        .replaceAll('-', '')}.xlsx`
        .replace(/[|&;$%@"<>()+, ]/g, '_');
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Table data');
      XLSX.writeFile(wb, fileName);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-bar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.pointer {
  cursor: pointer;
}
.tabs li {
  min-width: 5rem;
}
.help-text {
  min-width: 20rem;
  text-align: left;
}
.tootiptrigger{
  font-size: 1rem;
}
.tootiptrigger:hover {
  color: black !important;
}
.tip-icon {
  background: url('../../../assets/images/i_icon.svg');
  height: 16px;
  width: 16px;
  display: inline-flex;
}

.type-tag {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
  border-radius: 3px;
  visibility: hidden;
}

.is-inline-flex:hover {
  .type-tag {
    visibility: visible;
  }
}
</style>
