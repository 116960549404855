<template>
  <div
    v-if="isVisible"
    @click="backToTop"
    class="back-to-top"
    ref="backToTop"
  >
    <figure class="image is-32x32">
      <img
        :src="require('@/assets/images/icons/goup.svg')"
        alt="TedHy: Back to top"
      />
    </figure>
  </div>
</template>

<script>
export default {
  name: 'BackToTop',

  data() {
    return {
      isVisible: false,
    };
  },

  created() {
    window.addEventListener('scroll', this.showBackToTopButton);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.showBackToTopButton);
  },

  methods: {
    backToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },

    showBackToTopButton() {
      this.isVisible = window.scrollY > 200;
      if (this.isVisible && this.$refs.backToTop && !this.$refs.backToTop.classList.contains('visible')) {
        setTimeout(() => {
          this.$refs.backToTop.classList.toggle('visible');
        }, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 3vw;
  cursor: pointer;
  opacity: 0.5;
  padding: 0;
  width: 0;
  transition: width 100ms ease-in-out, padding 100ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.visible {
  width: 50px;
  padding: 10px;
}
</style>
