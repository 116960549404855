module.exports.diagrams = [
  {
    site: 'all',
    year: 2021,
    value: `
    graph LR
      subgraph energy [Energy sources]
        grid
        click grid href "#energy-raw-materials-anchor"
      end

      subgraph equipments [Equipments]
        dispenser
        click dispenser href "#equipment-anchor"
      end

      subgraph uses [Users and uses]
        bus_fleet
        click bus_fleet href "#uses-users-anchor"
      end

      grid --> dispenser
      dispenser --> bus_fleet
    `,
  },
  {
    site: 'all',
    year: 2022,
    value: `
    graph LR
      subgraph energy [Energy sources]
        grid
        click grid href "#energy-raw-materials-anchor"
      end

      subgraph equipments [Equipments]
        dispenser
        click dispenser href "#equipment-anchor"
      end

      subgraph uses [Users and uses]
        bus_fleet
        click bus_fleet href "#uses-users-anchor"
        taxi
        click taxi href "#uses-users-anchor"
      end

      grid --> dispenser
      dispenser --> bus_fleet
      dispenser --> taxi
    `,
  },
  {
    site: 'Perrache',
    year: 2021,
    value: `
    graph LR
      ProdPemM(ProdPemMx1.0)
      click ProdPemM href "#equipment-anchor"
      DistrPLXL(DistrPLXLx2.0)
      click DistrPLXL href "#equipment-anchor"
      CompMembL(CompMembLx1.0)
      click CompMembL href "#equipment-anchor"

      subgraph energy [Energy sources]
        elec_1{{PV Magny - Entrepôt Magny}}
        click elec_1 href "#energy-raw-materials-anchor"
        elec_2{{Electricité du réseau - Entrepôt Magny}}
        click elec_2 href "#energy-raw-materials-anchor"
      end

      subgraph uses [Users and uses]
        demand_1[[Poids lourds]]
        click demand_1 href "#uses-users-anchor"
      end

      elec_1-.->|930.0|ProdPemM
      elec_2-.->|930.0|ProdPemM
      elec_1 -.->|356.46|DistrPLXL

      subgraph equipment [Equipments]
        DistrPLXL-->|2227.87|demand_1
        ProdPemM-->|600.0|CompMembL
      end
    `,
  },
  {
    site: 'Perrache',
    year: 2022,
    value: `
      graph LR
        E --> F
    `,
  },
];
