<template>
  <ul>
    <li v-for="(tab) in tabs"
        :class="{ 'is-active': selectedTab === tab }"
        @click="$emit('update:selectedTab', tab)"
        :key="tab"
    >
      <a>{{tab}}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ChartToggleTabs.vue',

  props: {
    tabs: Array,
    selectedTab: String,
  },

};
</script>

<style lang="scss" scoped>
.tabs li {
  min-width: 5rem;
}
</style>
