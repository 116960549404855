<template>
  <p class="subtitle">Map</p>
</template>

<script>
export default {
  name: 'Map',
};
</script>

<style scoped>
</style>
