<template>
  <div class="container">
    <Collapsible id="pfd" headerLabel="Process Flow Diagram">
      <template v-slot:content>
        <div class="section-content">
          <TheFlowDiagram/>
        </div>
      </template>
    </Collapsible>
    <Collapsible
      id="energy-raw-materials"
      headerLabel="Energies & Raw materials"
    >
      <template v-slot:content>
        <div class="section-content">
          <section class="py-3">
          <h3 class="title is-size-6">Energy</h3>
            <p class="py-3">
              Wind farm: A wind farm is a group of wind turbines in the same
              location used to produce electricity.
            </p>
            <p class="py-3">
              Solar PV: Solar PV (Solar Photovoltaics) is the generation of
              electricity using energy from the sun.
            </p>
            <p class="py-3">
              Grid: An electrical grid, electric grid or power grid, is an
              interconnected network that delivers electricity from producers to
              consumers.
            </p>
          </section>
          <div
            v-for="(
              energyBalanceGroup, balanceIndex
            ) in chartgroups.energy_balance"
            :key="balanceIndex"
          >
            <ChartGroup
              :chart-group="energyBalanceGroup"
              :half="false"
              :isEnergyBalance="true"
            />
          </div>
          <div v-for="(chartGroup, index) in chartgroups.energy" :key="index">
            <ChartGroup
              :chart-group="chartGroup"
              :half="false"
            />
          </div>
          <section class="py-3">
          <h3 class="title is-size-6">Raw Material</h3>
            <p class="py-3">
              Deionized water: Deionized water is water that has had all of the
              ions removed it is typically used in the electrolysis process.
            </p>
            <p class="py-3">
              Nitrogen: Nitrogen is important to the chemical industry typically
              used for purging Hydrogen systems.
            </p>
          </section>
          <div
            v-for="(chartGroup, index) in chartgroups.raw_material"
            :key="index"
          >
            <ChartGroup
              :chart-group="chartGroup"
              :half="false"
            />
          </div>
        </div>
      </template>
    </Collapsible>
    <Collapsible id="equipment" headerLabel="Equipment">
      <template v-slot:content>
        <div class="section-content">
          <section class="py-3">
            <p class="title is-size-6">What is available</p>
            <p class="py-3">General overview of the equipment involved</p>
            <TheEquipmentTable/>
          </section>
          <section class="py-3">
            <p class="title is-size-6">Let's dive in</p>
            <p class="py-3">Understanding the equipment involved</p>
            <TheLetsDiveIn/>
          </section>
        </div>
      </template>
    </Collapsible>
    <Collapsible id="uses-users" headerLabel="Users & Use types">
      <template v-slot:content>
        <div class="section-content">
          <section class="py-3">
            <p class="py-3">
              Bus: A large motor vehicle carrying passengers by road, typically
              one serving the public on a fixed route.
            </p>
            <p class="py-3">
              Sedane: A car having a closed body and a closed boot separated from
              the part in which the driver and passengers sit; a saloon.
            </p>
            <p class="py-3">
              River shuttle: A form of transport that travels regularly between
              two places along a river.
            </p>
            <p class="py-3">
              City bus: The city bus operates within the city limits and is
              characterized by low maximum speed, low-ride platform, provision for
              standing and wheelchair passengers, two entrances on the curb side,
              low-back seats, and no luggage space. The suburban bus is designed
              for short intercity runs.
            </p>
            <p class="py-3">
              Taxi: A motor vehicle licensed to transport passengers in return for
              payment of a fare and typically fitted with a taximeter.
            </p>
            <p class="py-3">
              Personal vehicle: Personal vehicle means a noncommercial motor
              vehicle that is used by a transportation network company driver and
              is owned, leased, or otherwise authorized for use by the
              transportation network company driver.
            </p>
            <p class="py-3">
              Inner city ferry: Commercial service with terminals and boats for
              transporting persons, automobiles, etc., across the city river .
            </p>
          </section>
          <div
            v-for="(chartGroup, index) in chartgroups.user_use_type"
            :key="index"
          >
            <ChartGroup
              :chart-group="chartGroup"
              :half="false"
            />
          </div>
        </div>
      </template>
    </Collapsible>
  </div>
</template>

<script>

import TheFlowDiagram from '@/components/Scenario/TheFlowDiagram.vue';
import chartgroups from '@/assets/data/chartgroups.json';
import Collapsible from '@/components/Collapsible.vue';
import ChartGroup from '@/components/Scenario/Data/ChartGroup.vue';
import TheEquipmentTable from '../../../components/Scenario/Data/TheEquipmentTable.vue';
import TheLetsDiveIn from '../../../components/Scenario/Data/TheLetsDiveIn.vue';

export default {
  name: 'SupplyChain',
  components: {
    TheLetsDiveIn,
    TheEquipmentTable,
    Collapsible,
    ChartGroup,
    TheFlowDiagram,
  },
  data() {
    return {
      chartgroups,
    };
  },
};
</script>

<style lang="scss" scoped>
.section-content {
  margin: 10px;
}
</style>
