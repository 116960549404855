<template>
  <div class="offset-anchor" :id="`${id}-anchor`"></div>
  <div class="collapsible" @click="expand">
    <div class="p-1">
      <div class="collapsible-header subtitle bottom-border">
        <h2 class="p-1">
          <span>{{ headerLabel }}</span>
          <span class="is-pulled-right">
            <i class="fas fa-chevron-down"></i>
          </span>
        </h2>
      </div>
    </div>
  </div>
  <div
    class="collapsible-content"
    :class="{ 'is-expanded': isExpanded }"
  >
    <slot name="content">
      <div class="has-background-light">
        <p>Content</p>
      </div>
    </slot>
  </div>
</template>

<script>
/* This is a template component for collapsible items.
You must provide a unique id for pfd link anchors when you using multiple collapsible items
*/

export default {
  name: 'Collapsible',
  props: {
    id: {
      type: String,
      default: 'id',
    },
    headerLabel: String,
    isCollapsedOnMount: Boolean,
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  mounted() {
    if (!this.isCollapsedOnMount) this.expand();
  },

  methods: {
    expand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
/*This offset accounts for the height of the action menu*/
.offset-anchor {
  position: relative;
  top: -60px;
}

.bottom-border {
  border-bottom: solid thin lightgray;
}
.collapsible-header {
  cursor: pointer;
}

.collapsible-header:hover {
  background-color: $light;
}

.collapsible-content {
  max-height: 0;
  display: none;
}
.is-expanded {
  display: inherit;
  max-height: fit-content;
}
</style>
