<template>
  <div class="container">
    <p class="subtitle">Environmental Impact</p>
    <section>
      <ChartGroup
        v-for="(chartGroup, index) in chartgroups.environmental_impact"
        :key="index"
        :chart-group="chartGroup"
        :half="false"
      />
    </section>
  </div>
</template>

<script>
import chartgroups from '@/assets/data/chartgroups.json';
import ChartGroup from '@/components/Scenario/Data/ChartGroup.vue';

export default {
  name: 'EnvironmentalImpact',

  components: {
    ChartGroup,
  },

  data() {
    return {
      chartgroups,
    };
  },
};
</script>

<style scoped>
</style>
