<template>
<!-- eslint-disable max-len -->
  <g id="Groupe_10740" data-name="Groupe 10740">
    <path
      id="Tracé_4352"
      data-name="Tracé 4352"
      class="cls-1"
      d="M535.85,848c-169.53,1.68-308.34-134.39-310-303.93S360.22,235.7,529.76,234q4.2,0,8.42,0c169,.58,310,139.73,308.59,306.78C847,710.23,709.88,847.76,540.48,848Zm59.91-383.85c-23.5,3.45-41.13,6-58.76,8.69-23.41,3.46-46.87,6.64-70.17,10.74-4.07.7-7.45,5.28-11.17,8.07,2.38,3,4.13,7.27,7.24,8.68,25.33,11.92,27.74,16.14,21.22,42.65-2.37,9.66-5.5,19.13-8.25,28.7-11.18,38.81-24.51,77.17-33.06,116.55-8.33,38.34,20,64,58.77,56.06,41.49-8.52,65.36-38.77,86.12-72.13,1.59-2.56-.59-7.45-1-11.25-4.15,1-9.58.75-12.19,3.32a229,229,0,0,1-18,21.21,65.42,65.42,0,0,1-21.39,6.68,56.75,56.75,0,0,1-1.5-19c9.73-34.76,20.42-69.2,30.75-103.76,9.93-33.17,19.86-66.37,31.41-105.21ZM561.49,432.5a50.41,50.41,0,1,0-47.89-52.81c0,.44,0,.88-.05,1.32a48.85,48.85,0,0,0,46.14,51.42l1.78.06Z"
      transform="translate(-225.81 -234)"
    />
    <path
      id="Tracé_4353"
      data-name="Tracé 4353"
      class="cls-2"
      d="M595.71,464.12c-11.58,38.85-21.48,72-31.39,105.22-10.33,34.57-21,69-30.75,103.75a56.4,56.4,0,0,0,1.49,19,65.16,65.16,0,0,0,21.39-6.67,230.67,230.67,0,0,0,18-21.22c2.59-2.58,8-2.29,12.17-3.31.44,3.81,2.61,8.69,1,11.24-20.76,33.37-44.68,63.63-86.11,72.13-38.73,8-67.09-17.71-58.76-56,8.55-39.39,21.82-77.75,33-116.55,2.77-9.57,5.88-19,8.25-28.7,6.51-26.51,4.11-30.73-21.22-42.65-3.11-1.46-4.86-5.75-7.24-8.69,3.73-2.78,7.09-7.36,11.17-8.07,23.3-4.09,46.76-7.27,70.17-10.73C554.62,470.14,572.25,467.57,595.71,464.12Z"
      transform="translate(-225.81 -234)"
    />
    <path
      id="Tracé_4354"
      data-name="Tracé 4354"
      class="cls-2"
      d="M561.47,432.48a48.85,48.85,0,0,1-48-49.69c0-.6,0-1.2.07-1.79a52.13,52.13,0,0,1,51.59-49.26,50.41,50.41,0,1,1-2.69,100.78l-1,0Z"
      transform="translate(-225.81 -234)"
    />
  </g>
</template>
<style lang="scss" scoped>
.cls-2{fill:#fff;}
</style>
