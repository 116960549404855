<template>
  <div class="subtitle has-text-white has-text-weight-bold has-text-left">
    The Hy-Suite
  </div>
  <div class="columns is-gapless">
    <div
      v-for="tool in hySuiteTools"
      :key="tool"
      class="column has-text-centered"
    >
      <a
        :href="getToolUrl(tool.toLowerCase())"
        target="_blank"
        class="external-tool"
      >
        <figure class="image p-2 tool-icon">
          <img
            :src="require(`@/assets/images/logos/${tool.toLowerCase()}.svg`)"
            alt="FilHy"
          />
        </figure>
        <p class="has-text-white">{{ tool }}</p>
      </a>
    </div>
  </div>
  <div class="title is-2 has-text-white has-text-weight-bold has-text-centered">
    <span class="is-size-4 align-super">by</span>
    <a :href="getToolUrl('PERSEE_HOME')" target="_blank">
      <img
        width="100"
        height="100"
        src="@/assets/images/logos/persee.svg"
        alt="Persee"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'TheAllAppsMenu',

  data() {
    return {
      hySuiteTools: ['Filhy', 'Sophy', 'Mobhy', 'Tedhy'],
    };
  },

  methods: {
    getToolUrl(toolName) {
      return process.env[`VUE_APP_${toolName.toUpperCase()}_URL`];
    },
  },
};
</script>

<style lang="scss" scoped>
.tool-icon {
  width: fit-content;
  margin: auto;
}

.tool-icon:hover{
  background-color: $applicationTransparentWhite;
  border-radius: 50%;
  transition: background-color ease-in-out 0.3s;
}

.align-super{
  vertical-align: super;
}
</style>
