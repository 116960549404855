import numeral from 'numeral';

const convert = require('convert-units');

const utilities = {
  numeralFormat(number) {
    const formatedValue = numeral(number).format('0.[0] a');
    return Math.abs(number) >= 1000000
      ? formatedValue.toUpperCase()
      : formatedValue;
  },

  unitConvert(value, unit) {
    // TODO: check if the posibilites of the library fit our needs
    if (convert().possibilities().includes(unit)) {
      const bestUnit = convert(value).from(unit).toBest();
      return this.numeralFormat(bestUnit.val) + bestUnit.unit;
    }
    return this.numeralFormat(value) + unit;
  },

};

export default utilities;
