<template>
  <div class="notfound container is-max-widescreen">
    <article class="mt-6">
      <section class="mt-6 has-text-centered">
        <p class="has-text-black is-size-3 has-text-weight-bold">Oops!</p>
        <div class="is-relative">
          <p class="title primary-text is-size-0 has-text-weight-bold">
            404
          </p>
          <p class="has-text-black is-size-3 has-text-weight-bold">
            Page Not Found!
          </p>
          <figure class="search">
            <img src="@/assets/images/icon-search-large.svg" alt="icon-search" />
          </figure>
        </div>
        <p class="has-text-black is-size-5 mt-6">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <button class="button is-rounded goback-button mt-6" @click="$router.go(-1)">
          <span class="icon">
            <i class="fas fa-angle-left"></i>
          </span>
          <span>Go back</span>
        </button>
      </section>
    </article>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.notfound {
  min-height: 720px;
}
.primary-text{
  color: $applicationBlue;
}
.goback-button{
  background-color: $applicationBlue;
  color: white;
}
.is-size-0 {
  font-size: 12rem;
}
.search {
  position: absolute;
  left: 28%;
  top: 53%;
  width: 4.5rem;
}
</style>
