<template>
 <div
    class="dropdown"
    :class="{ 'is-active': isOpen }"
    v-click-away="close"
  >
    <div
      class="dropdown-trigger"
      @click="toggle"
    >
      <div
        class="button action-menu-dropdown-trigger-button"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span class="trigger-button-text">
          <slot name="trigger-text"/>
        </span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"/>
        </span>
      </div>
    </div>

    <div
      class="dropdown-menu"
      id="dropdown-menu"
      role="menu"
    >
      <div
        class="dropdown-content list"
        :class="{ 'scrollbar-styled': changeScrollbarAppearance }"
        @click="conditionalClose"
      >
        <slot name="content"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectionDropdownWrapper',

  props: {
    isMultipleSelection: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    changeScrollbarAppearance() {
      return window.navigator.appVersion.indexOf('Mac') === -1;
    },
  },

  methods: {
    close() {
      this.isOpen = false;
      this.$emit('closeDropdown');
    },
    conditionalClose() {
      if (!this.isMultipleSelection) {
        this.isOpen = false;
        this.$emit('closeDropdown');
      }
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },

};
</script>

<style lang="scss" scoped>
.action-menu-dropdown-trigger-button {
  display: flex;
  width: 12rem;
}
.trigger-button-text {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list {
  max-height: 50vh;
  width: 11.5rem;
  overflow: auto;
}

.scrollbar-styled {
  scrollbar-width: thin;
}

.scrollbar-styled::-webkit-scrollbar {
  width: 8px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
  -webkit-border-radius: 100px;
  position: absolute;
}
.scrollbar-styled::-webkit-scrollbar:horizontal {
  height: 0;
}

/* hover effect for both scrollbar area, and scrollbar 'thumb' */
.scrollbar-styled::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
  position: absolute;
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
.scrollbar-styled::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  -webkit-border-radius: 100px;
  position: absolute;
}
.scrollbar-styled::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
  border-radius: 100px;
  -webkit-border-radius: 100px;
  position: absolute;
}
</style>
