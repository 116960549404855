import Vuex from 'vuex';
import data from './modules/data';

export default Vuex.createStore({
  state: {
    subsidy: false,
    selectedScenario: null,
    graphColorSchemes: [
      {
        colorPalette5: ['#EA5425', '#FFBB43', '#0D82DB', '#088754', '#D90E37'],
        colorPalette10: ['#EA5425', '#FFBB43', '#0D82DB', '#088754', '#D90E37',
          '#EBAB97', '#F8D79A', '#B1D9F7', '#f0beca', '#F4C0CA',
        ],
      },
      {
        colorPalette5: ['#4BBBCE', '#39307C', '#5C8001', '#FFF031', '#553D36'],
        colorPalette10: ['#4BBBCE', '#39307C', '#5C8001', '#FFF031', '#553D36',
          '#B9E6ED', '#B2ACE2', '#D2EA97', '#FAF1AF', '#D3ADA2',
        ],
      },
    ],
    isSidebarMenuActive: false,
  },

  getters: {
  },

  mutations: {
    toggleSubsidy(state) {
      state.subsidy = !state.subsidy;
    },
    setSidebarMenuActive(state, isSidebarMenuActive) {
      state.isSidebarMenuActive = isSidebarMenuActive;
    },
  },

  actions: {
  },

  modules: {
    data,
  },
});
