<template>
  <div class="custom-button has-text-white is-clickable is-inline-flex">
    <p class="has-text-centered p-2 mx-2">Save</p>
    <img class="p-2"
         width="27"
         height="27"
         src="@/assets/images/icons/save_icon.svg"
         alt="download"
    />
  </div>
</template>

<script>
export default {
  name: 'DownloadButton',
};
</script>

<style lang="scss" scoped>
 .custom-button {
   border-radius: 8px;
   background: $applicationGreen;
 }

 p {
   font-size: 0.8rem;
 }

 img {
   background: #60c08e;
   border-top-right-radius: inherit;
   border-bottom-right-radius: inherit;
   transition: all ease-in-out 0.2s;
 }

 .custom-button:hover {
   img {
     background: #009c7d;
   }
 }
</style>
