<template>
<!-- eslint-disable max-len -->
  <g id="Groupe_10741" data-name="Groupe 10741">
    <circle
      id="Ellipse_238"
      data-name="Ellipse 238"
      class="cls-1"
      cx="307.23"
      cy="307.23"
      r="307.23"
    />
    <g id="Groupe_10687" data-name="Groupe 10687">
      <path
        id="Tracé_4600"
        data-name="Tracé 4600"
        class="cls-2"
        d="M415,439.07v55.61h-28V441.05a28.3,28.3,0,0,1,26.79-29.74,26.72,26.72,0,0,1,3.1,0H664.71a28.26,28.26,0,0,1,29.37,27.11c0,.75,0,1.51,0,2.26V633a28.13,28.13,0,0,1-26.72,29.46,27.09,27.09,0,0,1-2.85,0H417.08a28.08,28.08,0,0,1-29-20.93,24,24,0,0,1-1-5.77V579H415v55.73H665.88V439.26Z"
        transform="translate(-232.6 -233.54)"
      />
      <path
        id="Tracé_6921"
        data-name="Tracé 6921"
        class="cls-2"
        d="M571,533.55a101.35,101.35,0,0,0-6.5-9.31c-9.91-11.48-20.08-22.8-30.21-34.11a11,11,0,0,0-15.17-3.33,11.22,11.22,0,0,0-2.23,1.9,14.15,14.15,0,0,0,1.27,19.56c4.16,4.72,8.54,9.2,12.82,13.78l-.85,1.8H403.86a49.18,49.18,0,0,0-6.19.18,12.92,12.92,0,0,0,0,25.45,46,46,0,0,0,5.63.14H530.16l.83,1.78c-4.28,4.58-8.66,9.06-12.82,13.88a14.15,14.15,0,0,0-1.29,19.56,11,11,0,0,0,15.51.82,11.17,11.17,0,0,0,1.89-2.22q16-17.85,31.72-35.9a64.4,64.4,0,0,0,4.91-7.53Z"
        transform="translate(-232.6 -233.54)"
      />
    </g>
  </g>
</template>
<style lang="scss" scoped>
.cls-2 {
  fill: #fff;
}
</style>
