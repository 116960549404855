<template>
  <div class="box p-0">
    <ChartTitleBar
      :description="'A list of the equipments available in the supply chain'"
      :title="'Equipments'"
    />
    <div class="pt-2">
      <table class="table is-fullwidth is-narrow is-hoverable">
        <thead>
          <tr>
            <th v-for="column in columnsMeta"
                :key="column.code"
            >
              {{column.label}}
            </th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="dataItem in dataSource"
            :key="dataItem['model']"
            class="is-clickable"
        >
          <td v-for="column in columnsMeta"
              :key="column.code"
          >
            {{ dataItem[column.code] }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ChartTitleBar from './ChartTitleBar.vue';

export default {
  name: 'TheEquipmentTable',
  components: { ChartTitleBar },

  computed: {
    columnsMeta() {
      // TODO: This should be fetched dynamically
      return [
        {
          code: 'name',
          label: 'Name',
        },
        {
          code: 'model',
          label: 'Model',
        },
        {
          code: 'inst_year',
          label: 'Installation year',
        },
        {
          code: 'process_step',
          label: 'Process step',
        },
        {
          code: 'quantity',
          label: 'Quantity',
        }];
    },

    dataSource() {
      const isAllSiteSelected = this.$store.state.data.selectedSite === 'all';
      return isAllSiteSelected
        ? this.$store.state.data.equipmentsData
        : this.$store.state.data.equipmentsSiteData.filtered;
    },
  },
};
</script>

<style scoped>
</style>
