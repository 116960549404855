<template>
  <div class="dropdown is-hoverable"
       :class="{'is-right': isRightAligned}"
  >
    <div class="dropdown-trigger is-clickable">
      <div aria-haspopup="true"
           aria-controls="dropdown-menu"
      >
        <slot name="trigger"/>
      </div>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <div class="dropdown-item">
          <slot name="content"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HoverDropdownWrapper',
  props: {
    isRightAligned: Boolean,
  },
};
</script>

<style scoped>
.dropdown-menu {
  min-width: unset;
}
</style>
