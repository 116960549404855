<template>
  <!-- eslint-disable max-len -->
  <g id="Groupe_10742" data-name="Groupe 10742">
    <circle
      id="Ellipse_239"
      data-name="Ellipse 239"
      class="cls-1"
      cx="309.48"
      cy="309.48"
      r="309.48" />
    <g id="Groupe_10691" data-name="Groupe 10691">
      <path
        id="Tracé_4600"
        data-name="Tracé 4600"
        class="cls-2"
        d="M416.32,435.57v55.94H388.21V437.6a28.43,28.43,0,0,1,26.82-30,28.82,28.82,0,0,1,3.22,0h249a28.41,28.41,0,0,1,29.52,27.24c0,.76,0,1.53,0,2.3V630.29a28.27,28.27,0,0,1-26.85,29.6,25.68,25.68,0,0,1-2.91,0H418.34a28.24,28.24,0,0,1-29.18-21,24.24,24.24,0,0,1-1-5.8c-.07-18.92,0-37.85,0-57h28V632H668.37V435.57Z"
        transform="translate(-232.99 -229.04)" />
      <path
        id="Tracé_6921"
        data-name="Tracé 6921"
        class="cls-2"
        d="M412,530.54a104.51,104.51,0,0,1,6.54-9.36c10-11.54,20.24-22.91,30.35-34.29a11,11,0,0,1,15.23-3.34,11.17,11.17,0,0,1,2.26,1.92,14.29,14.29,0,0,1-1.26,19.65c-4.19,4.76-8.6,9.24-12.89,13.84l.85,1.82H580a49.5,49.5,0,0,1,6.21.18,13,13,0,0,1,0,25.58,44.26,44.26,0,0,1-5.64.13H453.06l-.84,1.8c4.3,4.61,8.7,9.11,12.88,13.84A14.29,14.29,0,0,1,466.39,582a11,11,0,0,1-15.6.81,10.69,10.69,0,0,1-1.9-2.23q-16-18-31.95-36.08a64.27,64.27,0,0,1-4.94-7.57Z"
        transform="translate(-232.99 -229.04)" />
    </g>
  </g>
</template>
<style lang="scss" scoped>
.cls-2 {
  fill: #fff;
}
</style>
