<template>
  <div ref="data-content">
    <div class="container my-4">
      <div>
        <Draggable
          v-model="preselectedCharts"
          group="preselectedCharts"
          item-key="index"
          ghost-class="ghost"
          :animation="300"
          handle=".handle"
          class="columns is-multiline is-vcentered"
        >
          <template #item="{ element }">
            <ChartGroup
              :chart-group="element"
              :half="true"
            />
          </template>
        </Draggable>
        <yearly-data-table
          v-for="table in tables"
          :key="table.code"
          :title="table.title"
          :include="table.include"
          :item-type="table.itemType"
          :source="table.source"
          :chart-type="table.chartType"
          :variable="table.variable"
          :tags="table.tags"
          :description="table.description"
          :id="table.code.toString()"
        />
      </div>
      <back-to-top />
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

import ChartGroup from '../../../components/Scenario/Data/ChartGroup.vue';
import BackToTop from '../../../components/BackToTop.vue';

import chartgroups from '../../../assets/data/chartgroups.json';
import tables from '../../../assets/data/tables.json';
import YearlyDataTable from '../../../components/Scenario/Data/YearlyDataTable.vue';

export default {
  name: 'Economics',
  components: {
    YearlyDataTable,
    Draggable,
    ChartGroup,
    BackToTop,
  },

  data() {
    return {
      contentTopPosition: null,
      chartgroups,
      preselectedCharts: null,
      tables,
    };
  },

  computed: {
    subsidy() {
      return this.$store.state.subsidy;
    },
  },

  methods: {
    preselectCharts() {
      const preselectedCharts = [];
      chartgroups.economics.forEach((group) => {
        preselectedCharts.push({
          title: group.title,
          tags: group.tags,
          tabs: group.tabs,
          defaultTab: group.defaultTab,
          chartList: group.chartList.filter((element) => (element.subsidy === undefined
            || element.subsidy === this.subsidy)),
        });
      });
      this.preselectedCharts = preselectedCharts;
    },
  },

  watch: {
    subsidy() {
      this.preselectCharts();
    },
  },

  created() {
    this.preselectCharts();
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.4;
}
</style>
