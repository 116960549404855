<template>
  <div @click="toggleSidebarMenu">
    <figure class="image is-32x32">
      <img :src="require('@/assets/images/icons/burger.svg')" alt="burger"/>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'BurgerButton',

  methods: {
    toggleSidebarMenu() {
      this.$store.commit('setSidebarMenuActive', !this.$store.state.isSidebarMenuActive);
    },
  },

};
</script>

<style lang="scss" scoped>
</style>
